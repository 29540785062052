import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image" 

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breakpoints from "../components/vars/breakpoints"
import Nav from "../components/nav/PrimaryNav"
import Colors from "../components/vars/colors"
import Footer from "../components/footers/index"
import Container from "../components/containers"

import CaretLeft from "../images/svg-icons/caret-left-solid.svg"
import CaretRight from "../images/svg-icons/caret-right-solid.svg"



const StyledBlogTileContainer = styled(Container.Margin)`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));

  @media only screen and (${Breakpoints.device.large}) {
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  }
`
const BlogTileContainer = (props) => {
  return (
    <StyledBlogTileContainer>{ props.children }</StyledBlogTileContainer>
  )
}


// Blog Tile Styles
const StyledBlogTile = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  .readtime { color: ${ Colors.grey.g5 } }
  position: relative;
  max-width 38rem;
  h3 { margin: 0 0 0.5rem 0; }
  h3 a {
    display: block;
    color: ${ Colors.text.primary };
    font-size: 0.8em;
    text-decoration: none;
  }
  .postmeta {
    padding: 1rem 2rem 2rem;
  }
  .authordate {
    font-size: 0.9em;
    @media only screen and (${ Breakpoints.device.medium }) {
      font-size: 1em;
    }
  }
  img {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .postsummary {
    margin-top: 1rem;
    font-size: 1.1em;
  }

  .readmore {
    margin-top: 0.75rem;
    a {
      color: ${ Colors.secondary.main };
      text-decoration: none;
    }
    a:hover { text-decoration: underline; }
  }

  @media only screen and (${ Breakpoints.device.medium }) {
    transition: top 0.2s, box-shadow 0.2s;
    top: 0rem;
    :hover {
      top: -0.1rem;
      box-shadow: 0 0.5rem 0.5rem 0.25rem rgba(0,0,0,0.1);
    }
  }
`
const BlogTile = (props) => {
  return (
    <StyledBlogTile>
      { props.children }
    </StyledBlogTile>
  )
}


const StyledBlogNav = styled(Container.Margin)`
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 1.2em;
  justify-content: center;
  a {
    text-decoration: none;
  }
  .navtext {
    .previous, .next {
      color: ${ Colors.text.primary };
      text-decoration: none;
    }
  }
  div:nth-child(1) > div:nth-child(1) > a:nth-child(2) {
    margin-left: 1.5rem;
  }
  svg {
    display: inline-block;
    position: relative;
    top: 0.25rem;
    height: 1em;
    margin: 0 0.5rem;
    path { color: ${ Colors.primary.main } }
  }
  .pagenumbers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0.75rem;
    gap: 0.5rem;
    a {
      width: 3rem;
      height: auto;
      text-align: center;
      display: inline-block;
      border: 1px solid ${ Colors.grey.g6 };
      padding: 0.5rem;
      background: none;
      color: ${ Colors.primary.main };
    }
    a[aria-current="page"] {
      background: #fff;
    }

  }
`
const BlogNav = (props) => {
  return (
    <StyledBlogNav tag="nav">
      { props.children }
    </StyledBlogNav>
  )
}








export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges

    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
    const nextPage = "/blog/" + (currentPage + 1).toString()    

    return (
      <React.Fragment>
        <Seo
          title='Blog'
          description='Our thoughts and experiences from technology to social engagement - all focused within the spectrum of recruitment.'
          keywords={['blog', 'video', 'recruitment', 'diversity', `news`]}
          />
          <Nav />
        <Layout>
          <BlogTileContainer>
            {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const summary = node.frontmatter.summary || node.excerpt
            const readingTime = node.fields.readingTime.text
            const image = getImage(node.frontmatter.image) || null;
            return (
              <BlogTile key={node.fields.slug}>
                { image != null && 
                  <Link to={ "/blog" + node.fields.slug }><GatsbyImage image={ image } alt="test"/></Link>
                }
                <div className="postmeta">
                <span className="readtime">{ readingTime }</span>
                  <h3><Link to={ "/blog" + node.fields.slug }>{ title }</Link></h3>
                    <p className="authordate">
                      <span>Posted { node.frontmatter.date } </span>
                      <span>by { node.frontmatter.author }</span>
                    </p>
                    <p className="postsummary">{ summary }</p>
                    <p className="readmore"><Link to={ "/blog" + node.fields.slug }>Read More</Link></p>
                </div>
              </BlogTile>
            )
            })}
          </BlogTileContainer>
            <BlogNav>
              <div>
                <div>
                  {/* Page Prev & Next */}
                  {!isFirst && (
                    <Link to={prevPage} rel="prev">
                      <span className="navtext">
                        <CaretLeft />
                        <span className="previous">Previous Page</span>
                      </span>
                    </Link>
                  )}

                  {!isLast && (
                    <Link to={nextPage} rel="next">
                      <span className="navtext">
                        <span className="next">Next Page</span>
                        <CaretRight />
                      </span>
                    </Link>
                  )}
                </div>
                <div className="pagenumbers">
                  {Array.from({ length: numPages }, (_, i) => (
                    <Link key={`pagination-number${i + 1}`} to={`/blog/${i === 0 ? "" : i + 1}`}>
                      {i + 1}
                    </Link>
                  ))}
                </div>
              </div>
            </BlogNav>
        </Layout>
        <Footer />
      </React.Fragment>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(fromNow: true)
            title
            author
            summary
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 700
                  aspectRatio: 1.777
                  quality: 80
                  placeholder: BLURRED
                  formats: [AUTO]
                )
              }
            }
          }
          excerpt (
              format: PLAIN
              pruneLength: 100
              truncate: false
          )
        }
      }
    }
  }
`